<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="mb-4">
      <p class="font-bold">report format</p>
      <span class="form-hint">your download will begin automatically after clicking the export to excel button</span>
    </div>
    <div class="flex-1 flex-col overflow-auto">
      <List
        resource="previewGlobalResponsesReport"
        :fields="fields"
        ref="list"
        :pageSize="18"
        trackBy="intent_response_id"
        :search="true"
        :requestParams="{sortField: '', direction: ''}"
      />
    </div>
    <modal-footer :footer="footer" :primary="onDownload" :tertiary="cancel"  />
  </div>
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import List from '@/components/digital_human/List'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import EventBus from "@/utils/EventBus"

export default {
  name: 'CreateResponse',
  components: { Loader, ModalFooter, List },
  mixins: [ModalNavigation, NotifyMixin],
   data() {
    return {
      loading: false,
      footer: {
        primaryButton: 'export to excel',
        tertiaryButton: 'cancel',
      },
      fields: [
        {
          name: 'intent_category_name',
          title: 'main topic',
          sortField: 'intent_category_name'
        },
        {
          name: 'intent_name_parent',
          title: 'conversation detail',
          sortField: 'intent_name_parent',
        },
        {
          name: 'intent_name',
          title: 'next level detail',
          sortField: 'intent_name'
        },
        {
          name: 'response_text',
          title: 'response',
          sortField: 'response_text',
        },
        {
          name: 'communities_using_this_response',
          title: 'communities using response',
          sortField: 'communities_using_this_response',
        },
        {
          name: 'default_response',
          title: 'default response',
          sortField: 'default_response',
        },
      ],
      fitleExtention: '',
    }
  },
  methods: {
    download(extention) {
        this.fitleExtention = extention;
        if (this.fitleExtention !== '')
          EventBus.emit('show-modal-download', { title: 'Download PDF', downloadStarted: 'download started'});
    },
    downloadProcess(data, extentionFile) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `global-response-report.${extentionFile}`);
      document.body.appendChild(link);
      link.click();
    },
    generateExcelReport() {
      this.$dhDataProvider.get('globalResponseReport', { responseType: 'blob',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-disposition': 'attachment',
          'format_file': 'xlsx'
        }})
        .then(response => {
          if (this.fitleExtention.trim() !== '') {
            this.fitleExtention = ''
            this.downloadProcess(response, 'xlsx');
          }
        })
        .catch((error) => this.notifyError(error))
        .finally(() => this.hideDownloadIndicator())
    },
    onDownload() {
      this.download('xlsx')
    },
    cancel() {
      this.redirect('responses.index')
    },
  },
  validations: {
    responseText: 'required',
  },
  created() {
    this.initValidator()
  },
  mounted() {
    EventBus.on('download-pdf', () => {
      switch (this.fitleExtention) {
        case 'xlsx':
          this.generateExcelReport()
          break;
        default:
          this.fitleExtention = ''
          break;
      }
    })
  }
}
</script>
<style scoped>
.form {
  width: 55%;
}

.label-container {
  width: 53%;
}

</style>
